import { Dialog, Transition } from "@headlessui/react";
import { IconX } from "@tabler/icons-react";
import { PRIMARY_BRAND_COLOR } from "lib/constants";
import { Fragment } from "react";

interface IProps {
  display: boolean;
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
}

const DialogLayout: React.FC<IProps> = ({ display, setDisplay, children }) => {
  return (
    <Transition appear show={display} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => setDisplay(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full sm:w-1/2 flex flex-col items-center transform overflow-hidden rounded-2xl bg-white p-6 text-left shadow-xl transition-all relative">
                <button
                  className="absolute top-2 right-2"
                  onClick={() => setDisplay(false)}
                >
                  <IconX color={PRIMARY_BRAND_COLOR} />
                </button>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DialogLayout;
